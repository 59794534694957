//var e4    = e4 || {},
//    acies = {};
//( function () {
//    'use strict';
//    jQuery( function () {
//        acies.init();
//    } );
//    acies.init = function () {
//        acies.redirects.init();
//    };
//    acies.redirects = {
//        init: function () {
//            if ( location.hash === '#logodan' ) {
//                jQuery( '.js-acies-redirect-' + location.hash.substring( 1 ) ).removeClass( 'd-none' );
//                jQuery( '#js-acies-redirect-modal' ).on( 'show.bs.modal', function () {
//                    location.hash = '';
//                } ).modal( 'show' );
//            }
//            // Cookie at "Redirect Modal" to only show once at Front Page.
//            if ( window.location.pathname == '/' ) {
//                if ( jQuery.cookie( 'aciesFrontpageShowOverlay' ) == null ) {
//                    jQuery.cookie( 'aciesFrontpageShowOverlay', 'yes', {
//                        expires: 7,
//                        path: '/'
//                    } );
//                    jQuery( '.js-acies-redirect-' + 'logodan' ).removeClass( 'd-none' );
//                    jQuery( '#js-acies-redirect-modal' ).on( 'show.bs.modal', function () {
//                        location.hash = '';
//                    } ).modal( 'show' );
//                }
//            }
//        }
//    };
//}() );